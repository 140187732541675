import { CommonModule, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxDropdownModule } from '@wdx/shared/components/wdx-dropdown';
import { ClientsService } from '../../../../core/services/clients/clients.service';
import { SelectFieldResult } from '@wdx/portal/api-models';

@Component({
    selector: 'cp-client-switcher',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        WdxIconModule,
        NgbDropdownModule,
        WdxDropdownModule,
    ],
    templateUrl: './client-switcher.component.html',
    styleUrls: ['./client-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientSwitcherComponent implements OnInit {
    clients?: SelectFieldResult[];
    activeClient?: SelectFieldResult;

    constructor(
        private location: Location,
        private clientsService: ClientsService,
    ) {}

    ngOnInit(): void {
        this.clients = this.clientsService.clients;
        this.activeClient = this.clients.find(
            (client) => client.id === this.clientsService.activeClientId,
        ) as SelectFieldResult;
    }

    switchClient(selectedClientId?: string | null) {
        this.clientsService.setActiveClient(selectedClientId as string);
        this.location.replaceState('/');
        window.location.reload();
    }
}
