<div class="d-flex align-items-center">
    @if (clients?.length && clients!.length > 1) {
        <div class="mb-1">Client:</div>
        <wdx-dropdown
            class="mb-1"
            ngbDropdown
            container="body"
            display="dynamic"
            #dropdown="ngbDropdown"
        >
            <button
                ngbDropdownToggle
                class="btn fw-normal d-flex align-items-center"
            >
                <span class="ms-1 fw-bold">{{ activeClient?.name }}</span>
                <wdx-icon
                    class="ms-2 toggle-icon"
                    icon="chevron-down"
                    [rotate]="dropdown.isOpen() ? 180 : 0"
                ></wdx-icon>
            </button>
            <wdx-dropdown-menu ngbDropdownMenu>
                <button
                    *ngFor="let client of clients"
                    class="d-flex"
                    ngbDropdownItem
                    (click)="switchClient(client.id)"
                >
                    {{ client.name }}
                    <wdx-icon
                        *ngIf="activeClient?.id === client?.id"
                        class="ms-2"
                        icon="check"
                    ></wdx-icon>
                </button>
            </wdx-dropdown-menu>
        </wdx-dropdown>
    } @else {
        Client:<span class="ms-1 fw-bold">{{ clients?.[0]?.name }}</span>
    }
</div>
