import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    OnInit,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppService } from '../../../../core/services/app/app.service';
import { BehaviorSubject } from 'rxjs';
import { ThemeService } from '../../../../libs/system/theme';
import { PushPipe } from '@ngrx/component';

@Component({
    selector: 'cp-client-logo',
    standalone: true,
    imports: [CommonModule, RouterModule, PushPipe],
    templateUrl: './client-logo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientLogoComponent implements OnInit {
    private themeService = inject(ThemeService);
    private appService = inject(AppService);

    tenantName$!: BehaviorSubject<string>;
    portalLogoUrl?: string | null;

    ngOnInit(): void {
        this.portalLogoUrl = this.themeService?.theme?.portalLogoUrl;
        this.tenantName$ = this.appService.tenantName$;
    }
}
